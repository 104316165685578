import React, {
  useState,
  useEffect,
  memo
} from 'react';

import { vars } from 'static/css/theme/vars.css';
import { offline } from 'static/css/theme/transition.css';

const p = {
  color: vars.color.black2,
  margin: vars.space.none
};


const OfflinePopup = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [showOnlineMessage, setShowOnlineMessage] = useState(false);
  const [lang, setLang] = useState('');

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    
    if (isBrowser) {
      const handleOnlineStatusChange = () => {
        setIsOnline(true);
        setShowOnlineMessage(true);
      };
      
      const handleOfflineStatusChange = () => setIsOnline(false);
      
      window.addEventListener('online', handleOnlineStatusChange);
      window.addEventListener('offline', handleOfflineStatusChange);

      setLang(document.documentElement.lang || 'pl');

      return () => {
        window.removeEventListener('online', handleOnlineStatusChange);
        window.removeEventListener('offline', handleOfflineStatusChange);
      };
    };
  }, []);

  useEffect(() => {
    if (showOnlineMessage) {
      const timeoutId = setTimeout(() => setShowOnlineMessage(false), 3500);
      return () => clearTimeout(timeoutId);
    };
  }, [showOnlineMessage]);

  useEffect(() => {
    const currentLang = document.documentElement.lang;
    if (currentLang !== lang) setLang(currentLang || 'pl');
  }, [lang]);

  const offlineMessage = lang === 'pl' ? 'Jesteś w trybie offline' : 'You are in offline mode';
  const onlineMessage = lang === 'pl' ? 'Jesteś w trybie online' : 'You are in online mode';

  if (!isOnline || showOnlineMessage) {
    return (
      <div className={isOnline ? offline.online : offline.offline}>
        <p style={p}>{isOnline ? onlineMessage : offlineMessage}</p>
      </div>
    );
  };

  return null;
};

export default memo(OfflinePopup);