import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';
import { 
  graphql,
  useStaticQuery
} from 'gatsby';

const isBrowser = typeof window !== 'undefined';


const CURRENCY_QUERY = graphql`
  query CurrencyQuery {
    zloty: allHygraphCurrency(filter: { code: { eq: "zł" } }) {
      nodes {
        default
        code
        rate
      }
    }
    euro: allHygraphCurrency(filter: { code: { eq: "eur" } }) {
      nodes {
        default
        code
        rate
      }
    }
  }
`;

const CurrencyContext = createContext();

export function useCurrency() {
  const context = useContext(CurrencyContext);

  if (!isBrowser) {
    return { currencyCode: 'zł', currencyRate: 1 };
  };

  return context || { currencyCode: 'zł', currencyRate: 1 };
};

function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    if (isBrowser) {
      const storedValue = localStorage.getItem(key);

      return storedValue ? JSON.parse(storedValue) : initialValue;
    };

    return initialValue;
  });

  useEffect(() => {
    if (isBrowser) {
      localStorage.setItem(key, JSON.stringify(value));
    };
  }, [key, value]);

  return [value, setValue];
};

export function CurrencyProvider({ children }) {
  const [isCurrency, setIsCurrency] = useLocalStorage('currency', false);

  const toggleCurrency = () => {
    setIsCurrency((prevIsCurrency) => !prevIsCurrency);
  };

  const currencyQuery = useStaticQuery(CURRENCY_QUERY);

  const currencyData = isCurrency ? currencyQuery.euro : currencyQuery.zloty;

  const currencyCode =
    currencyData.nodes.length > 0 ? currencyData.nodes[0].code : '';
  const currencyRate = currencyData.nodes.find((node) => node.code === currencyCode)?.rate || 1;

  const value = {
    currencyCode,
    currencyRate,
    toggleCurrency
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};