import React, {
  useState,
  useContext,
  useEffect,
  memo
} from 'react';

import { ThemeContext } from 'components/context/themeContext';
import favicon from 'images/favicon.webp';

import { dark, light } from 'static/css/theme/vars.css';
import { transition } from 'static/css/theme/transition.css';


const TransitionProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);
  const themeContext = useContext(ThemeContext);
  const isDarkMode = themeContext?.isDarkMode || false;

  useEffect(() => {
    const handlePageLoad = () => {
      setTimeout(() => {
        setIsVisible(false);
      }, 500);
    };

    if (document.readyState === 'complete') {
      handlePageLoad();
    } else {
      window.addEventListener('load', handlePageLoad);
    };

    return () => {
      window.removeEventListener('load', handlePageLoad);
    };
  }, []);

  const size = 192;

  return (
    <div className={`${isDarkMode ? dark : light}`} id='___transition'>
      <div className={`${isVisible ? transition.visible : transition.invisible}`}>
        <div className={transition.popup}>
          <div className={transition.image}>
            <img
             src={favicon}
             alt="Niezłe Ziółko"
             width={size}
             height={size}
            />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default memo(TransitionProvider);