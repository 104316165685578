import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';


const LocaleContext = createContext();

export function useLocale() {
  return useContext(LocaleContext);
};

export function LocaleProvider({ locales, remoteId, children }) {
  const [lang, setLang] = useState(locales);
  const [id, setId] = useState(remoteId);

  useEffect(() => {
    document.documentElement.setAttribute('lang', lang);
    document.documentElement.setAttribute('id', id);
  }, [lang, id]);

  useEffect(() => {
    setLang(locales);
    setId(remoteId);
  }, [remoteId, locales]);

  const contextValue = {
    locales,
    remoteId,
    lang,
    id,
    setLang,
    setId
  };

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};