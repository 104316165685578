import React, {
  useContext,
  useEffect,
  memo
} from 'react';
import {
  Slice,
  Script
} from 'gatsby';

import { ThemeContext } from 'components/context/themeContext';
import { dark, light, responsiveTheme } from 'static/css/theme/vars.css';
import { content } from 'static/css/theme/contents.css';


function Layout(props) {
  const { isDarkMode } = useContext(ThemeContext);
  const isBrowser = typeof window !== 'undefined';

  useEffect(() => {
    if (isBrowser) {
      if (isDarkMode) {
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      } else {
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
      };
    };
  }, [
    isDarkMode, 
    isBrowser
  ]);

  return (
    <div className={isDarkMode ? dark : light}>
      <div className={responsiveTheme}>
        <Slice alias='header' isDarkMode={isDarkMode} allowEmpty />
        <main className={content.main}>{props.children}</main>
        <Slice alias='footer' isDarkMode={isDarkMode} allowEmpty />
      </div>
      {isBrowser && !window.zaraz && (
        <Script src='https://niezleziolko.app/cdn-cgi/zaraz/i.js' async />
      )}
    </div>
  );
};

export default memo(Layout);