import React, {
  createContext,
  useState,
  useEffect
} from 'react';


export const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isAutoMode, setIsAutoMode] = useState(true);

  useEffect(() => {
    const preferredMode = window.localStorage.getItem('theme');

    if (typeof window !== 'undefined') {
      if (preferredMode === 'auto-dark') {
        setIsAutoMode(true);
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkMode(prefersDarkMode);
        if (prefersDarkMode) {
          setDarkMode();
        } else {
          setLightMode();
        };
      } else if (preferredMode === 'auto-light') {
        setIsAutoMode(true);
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkMode(!prefersDarkMode);
        if (prefersDarkMode) {
          setDarkMode();
        } else {
          setLightMode();
        };
      } else if (preferredMode === 'dark') {
        setDarkMode();
      } else if (preferredMode === 'light') {
        setLightMode();
      } else {
        setIsAutoMode(true);
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkMode(prefersDarkMode);
        if (prefersDarkMode) {
          setDarkMode();
        } else {
          setLightMode();
        };
      };
    };
  }, []);

  const toggleTheme = () => {
    setIsAutoMode(false);
    setIsDarkMode((prevMode) => !prevMode);
    
    if (typeof document !== 'undefined') {
      document.documentElement.classList.toggle('dark');
      document.documentElement.classList.toggle('light');
      localStorage.setItem('theme', isDarkMode ? 'light' : 'dark');
    };
  };

  const setDarkMode = () => {
    if (typeof document !== 'undefined') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      localStorage.setItem('theme', 'dark');
    };

    setIsDarkMode(true);
    setIsAutoMode(false);
  };

  const setLightMode = () => {
    if (typeof document !== 'undefined') {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    };
    
    setIsDarkMode(false);
    setIsAutoMode(false);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, isAutoMode }}>
      {children}
    </ThemeContext.Provider>
  );
};