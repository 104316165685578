exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-auth-downloads-jsx": () => import("./../../../src/templates/auth/downloads.jsx" /* webpackChunkName: "component---src-templates-auth-downloads-jsx" */),
  "component---src-templates-auth-my-account-jsx": () => import("./../../../src/templates/auth/myAccount.jsx" /* webpackChunkName: "component---src-templates-auth-my-account-jsx" */),
  "component---src-templates-auth-tops-jsx": () => import("./../../../src/templates/auth/tops.jsx" /* webpackChunkName: "component---src-templates-auth-tops-jsx" */),
  "component---src-templates-cart-jsx": () => import("./../../../src/templates/cart.jsx" /* webpackChunkName: "component---src-templates-cart-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-competition-jsx": () => import("./../../../src/templates/competition.jsx" /* webpackChunkName: "component---src-templates-competition-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-delivery-jsx": () => import("./../../../src/templates/delivery.jsx" /* webpackChunkName: "component---src-templates-delivery-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-loyalty-jsx": () => import("./../../../src/templates/loyalty.jsx" /* webpackChunkName: "component---src-templates-loyalty-jsx" */),
  "component---src-templates-newsletter-cancel-jsx": () => import("./../../../src/templates/newsletterCancel.jsx" /* webpackChunkName: "component---src-templates-newsletter-cancel-jsx" */),
  "component---src-templates-newsletter-jsx": () => import("./../../../src/templates/newsletter.jsx" /* webpackChunkName: "component---src-templates-newsletter-jsx" */),
  "component---src-templates-payment-jsx": () => import("./../../../src/templates/payment.jsx" /* webpackChunkName: "component---src-templates-payment-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-promotion-jsx": () => import("./../../../src/templates/promotion.jsx" /* webpackChunkName: "component---src-templates-promotion-jsx" */),
  "component---src-templates-redirects-jsx": () => import("./../../../src/templates/redirects.jsx" /* webpackChunkName: "component---src-templates-redirects-jsx" */),
  "component---src-templates-regulations-jsx": () => import("./../../../src/templates/regulations.jsx" /* webpackChunkName: "component---src-templates-regulations-jsx" */),
  "slice---src-slices-banners-partner-banner-banner-jsx": () => import("./../../../src/slices/banners/partnerBanner/banner.jsx" /* webpackChunkName: "slice---src-slices-banners-partner-banner-banner-jsx" */),
  "slice---src-slices-banners-top-banner-jsx": () => import("./../../../src/slices/banners/topBanner.jsx" /* webpackChunkName: "slice---src-slices-banners-top-banner-jsx" */),
  "slice---src-slices-bars-about-us-bar-jsx": () => import("./../../../src/slices/bars/aboutUs/bar.jsx" /* webpackChunkName: "slice---src-slices-bars-about-us-bar-jsx" */),
  "slice---src-slices-bars-product-bar-bar-jsx": () => import("./../../../src/slices/bars/productBar/bar.jsx" /* webpackChunkName: "slice---src-slices-bars-product-bar-bar-jsx" */),
  "slice---src-slices-buttons-add-to-cart-jsx": () => import("./../../../src/slices/buttons/addToCart.jsx" /* webpackChunkName: "slice---src-slices-buttons-add-to-cart-jsx" */),
  "slice---src-slices-buttons-client-jsx": () => import("./../../../src/slices/buttons/client.jsx" /* webpackChunkName: "slice---src-slices-buttons-client-jsx" */),
  "slice---src-slices-buttons-contact-jsx": () => import("./../../../src/slices/buttons/contact.jsx" /* webpackChunkName: "slice---src-slices-buttons-contact-jsx" */),
  "slice---src-slices-buttons-social-jsx": () => import("./../../../src/slices/buttons/social.jsx" /* webpackChunkName: "slice---src-slices-buttons-social-jsx" */),
  "slice---src-slices-carousel-jsx": () => import("./../../../src/slices/carousel.jsx" /* webpackChunkName: "slice---src-slices-carousel-jsx" */),
  "slice---src-slices-cart-cart-jsx": () => import("./../../../src/slices/cart/cart.jsx" /* webpackChunkName: "slice---src-slices-cart-cart-jsx" */),
  "slice---src-slices-cart-coupons-jsx": () => import("./../../../src/slices/cart/coupons.jsx" /* webpackChunkName: "slice---src-slices-cart-coupons-jsx" */),
  "slice---src-slices-cart-plant-jsx": () => import("./../../../src/slices/cart/plant.jsx" /* webpackChunkName: "slice---src-slices-cart-plant-jsx" */),
  "slice---src-slices-category-description-jsx": () => import("./../../../src/slices/categoryDescription.jsx" /* webpackChunkName: "slice---src-slices-category-description-jsx" */),
  "slice---src-slices-client-downloads-jsx": () => import("./../../../src/slices/client/downloads.jsx" /* webpackChunkName: "slice---src-slices-client-downloads-jsx" */),
  "slice---src-slices-client-my-account-jsx": () => import("./../../../src/slices/client/myAccount.jsx" /* webpackChunkName: "slice---src-slices-client-my-account-jsx" */),
  "slice---src-slices-footer-jsx": () => import("./../../../src/slices/footer.jsx" /* webpackChunkName: "slice---src-slices-footer-jsx" */),
  "slice---src-slices-forms-newsletter-cancel-jsx": () => import("./../../../src/slices/forms/newsletterCancel.jsx" /* webpackChunkName: "slice---src-slices-forms-newsletter-cancel-jsx" */),
  "slice---src-slices-forms-newsletter-jsx": () => import("./../../../src/slices/forms/newsletter.jsx" /* webpackChunkName: "slice---src-slices-forms-newsletter-jsx" */),
  "slice---src-slices-header-jsx": () => import("./../../../src/slices/header.jsx" /* webpackChunkName: "slice---src-slices-header-jsx" */),
  "slice---src-slices-omnibus-jsx": () => import("./../../../src/slices/omnibus.jsx" /* webpackChunkName: "slice---src-slices-omnibus-jsx" */),
  "slice---src-slices-popups-birthday-jsx": () => import("./../../../src/slices/popups/birthday.jsx" /* webpackChunkName: "slice---src-slices-popups-birthday-jsx" */),
  "slice---src-slices-popups-upsell-jsx": () => import("./../../../src/slices/popups/upsell.jsx" /* webpackChunkName: "slice---src-slices-popups-upsell-jsx" */),
  "slice---src-slices-product-list-jsx": () => import("./../../../src/slices/productList.jsx" /* webpackChunkName: "slice---src-slices-product-list-jsx" */),
  "slice---src-slices-prompt-jsx": () => import("./../../../src/slices/prompt.jsx" /* webpackChunkName: "slice---src-slices-prompt-jsx" */),
  "slice---src-slices-regards-jsx": () => import("./../../../src/slices/regards.jsx" /* webpackChunkName: "slice---src-slices-regards-jsx" */),
  "slice---src-slices-stamped-carousel-jsx": () => import("./../../../src/slices/stamped/carousel.jsx" /* webpackChunkName: "slice---src-slices-stamped-carousel-jsx" */),
  "slice---src-slices-stamped-list-jsx": () => import("./../../../src/slices/stamped/list.jsx" /* webpackChunkName: "slice---src-slices-stamped-list-jsx" */),
  "slice---src-slices-stamped-panel-jsx": () => import("./../../../src/slices/stamped/panel.jsx" /* webpackChunkName: "slice---src-slices-stamped-panel-jsx" */),
  "slice---src-slices-stamped-title-jsx": () => import("./../../../src/slices/stamped/title.jsx" /* webpackChunkName: "slice---src-slices-stamped-title-jsx" */),
  "slice---src-slices-swiper-jsx": () => import("./../../../src/slices/swiper.jsx" /* webpackChunkName: "slice---src-slices-swiper-jsx" */)
}

